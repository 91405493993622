import habitApi from '../../root/habitApi';

export const REQUEST_THREADS_BEGIN = 'REQUEST_THREADS_BEGIN';
export const REQUEST_THREADS_SUCCESS = 'REQUEST_THREADS_SUCCESS';
export const REQUEST_THREADS_FAILURE = 'REQUEST_THREADS_FAILURE';

export const REQUEST_ROOT_BEGIN = 'REQUEST_ROOT_BEGIN';
export const REQUEST_ROOT_SUCCESS = 'REQUEST_ROOT_SUCCESS';
export const REQUEST_ROOT_FAILURE = 'REQUEST_ROOT_FAILURE';

export const NEW_THREAD_BEGIN = 'NEW_THREAD_BEGIN';
export const NEW_THREAD_SUCCESS = 'NEW_THREAD_SUCCESS';
export const NEW_THREAD_FAILURE = 'NEW_THREAD_FAILURE';

export const UPDATE_THREAD_BEGIN = 'UPDATE_THREAD_BEGIN';
export const UPDATE_THREAD_SUCCESS = 'UPDATE_THREAD_SUCCESS';
export const UPDATE_THREAD_FAILURE = 'UPDATE_THREAD_FAILURE';

export const UPDATE_THREAD_SUBITEM_BEGIN = 'UPDATE_THREAD_BEGIN';
export const UPDATE_THREAD_SUBITEM_SUCCESS = 'UPDATE_THREAD_SUCCESS';
export const UPDATE_THREAD_SUBITEM_FAILURE = 'UPDATE_THREAD_FAILURE';

export const DELETE_THREAD_BEGIN = 'DELETE_THREAD_BEGIN';
export const DELETE_THREAD_SUCCESS = 'DELETE_THREAD_SUCCESS';
export const DELETE_THREAD_FAILURE = 'DELETE_THREAD_FAILURE';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function requestThreads() {
  return dispatch => {
    dispatch(requestThreadsBegin());
    return habitApi.endpoints.threads.getAll()
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(requestThreadsSuccess(json));
        return json;
      })
      .catch(error => dispatch(requestThreadsFailure(error)));
  };

  function requestThreadsBegin() { return { type: REQUEST_THREADS_BEGIN }; }
  function requestThreadsSuccess(threads) { return { type: REQUEST_THREADS_SUCCESS, threads }; }
  function requestThreadsFailure(error) { return { type: REQUEST_THREADS_FAILURE, error }; }

}

export function requestRootThread() {
  return dispatch => {
    dispatch(requestBegin());
    return habitApi.endpoints.threads.getOne({ id: 'root' })
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(requestSuccess(json));
        return json;
      })
      .catch(error => dispatch(requestFailure(error)));
  };

  function requestBegin() { return { type: REQUEST_ROOT_BEGIN }; }
  function requestSuccess(thread) { return { type: REQUEST_ROOT_SUCCESS, thread }; }
  function requestFailure(error) { return { type: REQUEST_ROOT_FAILURE, error }; }

}

export function newThread(thread) {
  return dispatch => {
    dispatch(newThreadBegin());
    return habitApi.endpoints.threads.create(thread)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(newThreadSuccess(json));
        return json;
      })
      .catch(error => dispatch(newThreadFailure(error)));
  };

  function newThreadBegin() { return { type: NEW_THREAD_BEGIN }; }
  function newThreadSuccess(json) { return { type: NEW_THREAD_SUCCESS, newThread: json }; }
  function newThreadFailure(error) { return { type: NEW_THREAD_FAILURE, error }; }
}

export function deleteThread(thread) {
  return dispatch => {
    dispatch(deleteThreadBegin());
    return habitApi.endpoints.threads.delete(thread)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(deleteThreadSuccess(thread));
        return json;
      })
      .catch(error => dispatch(deleteThreadFailure(error)));
  };
  function deleteThreadBegin() { return { type: DELETE_THREAD_BEGIN }; }
  function deleteThreadSuccess(thread) { return { type: DELETE_THREAD_SUCCESS, thread }; }
  function deleteThreadFailure(error) { return { type: DELETE_THREAD_FAILURE, error: error }; }
}

export function updateThread(thread) {
  return dispatch => {
    dispatch(updateThreadBegin());
    return habitApi.endpoints.threads.update(thread)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(updateThreadSuccess(json));
        return json;
      })
      .catch(error => dispatch(updateThreadFailure(error)));
  };
  function updateThreadBegin() { return { type: UPDATE_THREAD_BEGIN }; }
  function updateThreadSuccess(item) { return { type: UPDATE_THREAD_SUCCESS, item }; }
  function updateThreadFailure(error) { return { type: UPDATE_THREAD_FAILURE, error }; }
}
