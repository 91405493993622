import habitApi from '../../root/habitApi';

export const REQUEST_CONTEXT_BEGIN = 'REQUEST_CONTEXT_BEGIN';
export const REQUEST_CONTEXT_SUCCESS = 'REQUEST_CONTEXT_SUCCESS';
export const REQUEST_CONTEXT_FAILURE = 'REQUEST_CONTEXT_FAILURE';

export const NEW_CONTEXT_BEGIN = 'NEW_CONTEXT_BEGIN';
export const NEW_CONTEXT_SUCCESS = 'NEW_CONTEXT_SUCCESS';
export const NEW_CONTEXT_FAILURE = 'NEW_CONTEXT_FAILURE';

export const UPDATE_CONTEXT_BEGIN = 'UPDATE_CONTEXT_BEGIN';
export const UPDATE_CONTEXT_SUCCESS = 'UPDATE_CONTEXT_SUCCESS';
export const UPDATE_CONTEXT_FAILURE = 'UPDATE_CONTEXT_FAILURE';

export const DELETE_CONTEXT_BEGIN = 'DELETE_CONTEXT_BEGIN';
export const DELETE_CONTEXT_SUCCESS = 'DELETE_CONTEXT_SUCCESS';
export const DELETE_CONTEXT_FAILURE = 'DELETE_CONTEXT_FAILURE';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export function requestContext() {
  return dispatch => {
    dispatch(requestContextBegin());
    return habitApi.endpoints.context.getAll()
      .then(habitApi.handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(requestContextSuccess(json));
        return json;
      })
      .catch(error => dispatch(requestContextFailure(error)));
  };

  function requestContextBegin() { return { type: REQUEST_CONTEXT_BEGIN }; }
  function requestContextSuccess(context) { return { type: REQUEST_CONTEXT_SUCCESS, context }; }
  function requestContextFailure(error) { return { type: REQUEST_CONTEXT_FAILURE, error }; }

}

export function deleteContext(context) {
  return dispatch => {
    dispatch(deleteContextBegin());
    return habitApi.endpoints.context.delete(context)
      .then(habitApi.handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(deleteContextSuccess(context));
        return json;
      })
      .catch(error => dispatch(deleteContextFailure(error)));
  };
  function deleteContextBegin() { return { type: DELETE_CONTEXT_BEGIN }; }
  function deleteContextSuccess(context) { return { type: DELETE_CONTEXT_SUCCESS, context }; }
  function deleteContextFailure(error) { return { type: DELETE_CONTEXT_FAILURE, error: error }; }
}

export function updateContext(context) {
  return dispatch => {
    dispatch(updateContextBegin());
    return habitApi.endpoints.context.update(context)
      .then(habitApi.handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(updateContextSuccess(json));
        return json;
      })
      .catch(error => dispatch(updateContextFailure(error)));
  };

  function updateContextBegin() { return { type: UPDATE_CONTEXT_BEGIN }; }
  function updateContextSuccess(context) { return { type: UPDATE_CONTEXT_SUCCESS, context }; }
  function updateContextFailure(error) { return { type: UPDATE_CONTEXT_FAILURE, error: error }; }

}

export function newContext(context) {
  return dispatch => {
    dispatch(newContextBegin());
    return habitApi.endpoints.context.create(context)
      .then(habitApi.handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(newContextSuccess(json));
        return json;
      })
      .catch(error => dispatch(newContextFailure(error)));
  };

  function newContextBegin() { return { type: NEW_CONTEXT_BEGIN }; }
  function newContextSuccess(json) { return { type: NEW_CONTEXT_SUCCESS, newContext: json }; }
  function newContextFailure(error) { return { type: NEW_CONTEXT_FAILURE, error: error }; }
}

export function openModal(modalContextId) {
  return {
    type: OPEN_MODAL,
    modalContextId
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL
  };
}
