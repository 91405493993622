import habitApi from '../../root/habitApi';

export const REQUEST_ITEMS_BEGIN = 'REQUEST_ITEMS_BEGIN';
export const REQUEST_ITEMS_SUCCESS = 'REQUEST_ITEMS_SUCCESS';
export const REQUEST_ITEMS_FAILURE = 'REQUEST_ITEMS_FAILURE';

export const NEW_ITEM_BEGIN = 'NEW_ITEM_BEGIN';
export const NEW_ITEM_SUCCESS = 'NEW_ITEM_SUCCESS';
export const NEW_ITEM_FAILURE = 'NEW_ITEM_FAILURE';

export const UPDATE_ITEM_BEGIN = 'UPDATE_ITEM_BEGIN';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAILURE = 'UPDATE_ITEM_FAILURE';

export const DELETE_ITEM_BEGIN = 'DELETE_ITEM_BEGIN';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'DELETE_ITEM_FAILURE';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function requestItems() {
  return dispatch => {
    dispatch(requestItemsBegin());
    return habitApi.endpoints.items.getAll()
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(requestItemsSuccess(json));
        return json;
      })
      .catch(error => dispatch(requestItemsFailure(error)));
  };

  function requestItemsBegin() { return { type: REQUEST_ITEMS_BEGIN }; }
  function requestItemsSuccess(items) { return { type: REQUEST_ITEMS_SUCCESS, items }; }
  function requestItemsFailure(error) { return { type: REQUEST_ITEMS_FAILURE, error }; }

}

export function deleteItem(item) {
  return dispatch => {
    dispatch(deleteItemBegin());
    return habitApi.endpoints.items.delete(item)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(deleteItemSuccess(item));
        return json;
      })
      .catch(error => dispatch(deleteItemFailure(error)));
  };
  function deleteItemBegin() { return { type: DELETE_ITEM_BEGIN }; }
  function deleteItemSuccess(item) { return { type: DELETE_ITEM_SUCCESS, item }; }
  function deleteItemFailure(error) { return { type: DELETE_ITEM_FAILURE, error: error }; }
}

export function updateItem(item) {
  return dispatch => {
    dispatch(updateItemBegin());
    return habitApi.endpoints.items.update(item)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(updateItemSuccess(json));
        return json;
      })
      .catch(error => dispatch(updateItemFailure(error)));
  };

  function updateItemBegin() { return { type: UPDATE_ITEM_BEGIN }; }
  function updateItemSuccess(item) { return { type: UPDATE_ITEM_SUCCESS, item }; }
  function updateItemFailure(error) { return { type: UPDATE_ITEM_FAILURE, error: error }; }

}

export function newItem(item) {
  return dispatch => {
    dispatch(newItemBegin());
    return habitApi.endpoints.items.create(item)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(newItemSuccess(json));
        return json;
      })
      .catch(error => dispatch(newItemFailure(error)));
  };

  function newItemBegin() { return { type: NEW_ITEM_BEGIN }; }
  function newItemSuccess(json) { return { type: NEW_ITEM_SUCCESS, newItem: json }; }
  function newItemFailure(error) { return { type: NEW_ITEM_FAILURE, error: error }; }
}
