import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Box from '@mui/material/Box';
import { Checkbox } from '@mui/material';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

const Task = function ({name, completed}) {

  return (
    <Box sx={{ 
      alignItems: 'center', 
      display: 'flex', 
      flexDirection: 'row', 
      justifyContent: 'flex-end',
      padding: '4px' 
    }}>
      <IconButton
        sx={{ padding: 0 }}
        variant='contained'
        color='secondary'
        size="small">
        <Checkbox sx={{ padding: 0 }} checked={completed}/>
      </IconButton>
      <TextField
        sx={{ flexGrow: 1, marginLeft: '4px' }}
        value={name}
        variant='standard' />
      {/* <IconButton
              sx={{flexGrow: 1}}
              variant='contained'
              color='secondary'
              size="small">
              <Edit />
            </IconButton> */}
      <IconButton
        sx={{ padding: 0 }}
        variant='contained'
        color='primary'
        size="small">
        <ArrowBackIosNewIcon />
      </IconButton>
    </Box>
  );
}

Task.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  text: PropTypes.string,
  item: PropTypes.object,
  new: PropTypes.bool,
  toggleOpen: PropTypes.bool,
  threadId: PropTypes.string
};

export default Task;
