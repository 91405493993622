import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import ContextComponent from './ContextComponent';
import ContextModal from './ContextModal';
import { requestContext, updateContext } from '../actions';
import './context.css';

import { SortableTree } from "dnd-kit-sortable-tree";

const MinimalViable = () => {
  const dispatch = useDispatch();
  const sortableData = useSelector((state) => state.context.sortableData);
  const contextById = useSelector((state) => state.context.byId);

  const [items, setItems] = useState(sortableData);

  useEffect(() => {
    dispatch(requestContext());
  }, [dispatch]);

  useEffect(() => {
    setItems(sortableData);
  }, [sortableData]);

  const preSetItems = (data, info) => {
    if (info?.type === 'dropped') {
      const { draggedFromParent, droppedToParent, draggedItem } = info;
      if (droppedToParent !== undefined && draggedFromParent !== undefined) {
        let fromCx = contextById[draggedFromParent.id];
        let toCx = contextById[droppedToParent.id];

        if (!fromCx.children) {
          fromCx.children = [];
        }

        if (!toCx.children) {
          toCx.children = [];
        }
        if (!toCx.children.context) {
          toCx.children.context = [];
        }

        const fromIx = fromCx.children.indexOf(draggedItem.id);
        if (fromIx > -1) {

          // const removed = fromCx.children.context.splice(fromIx, 1);
          const fromChildren = [...fromCx.children]; // probably unnecessary 
          fromCx.children = fromChildren;

          const toChildren = [...toCx.children, draggedItem.id];
          toCx.children = toChildren;

          dispatch(updateContext(fromCx)).then(() => dispatch(updateContext(toCx)));
        }
      }
      else {
        // setItems(sortableData);
      }
      // when draggedToParent is null the drop is to root and should be rejected
    }
    else {
      setItems(data);
    }
  }

  return (
    <SortableTree
      className="bodytable"
      items={items}
      indentationWidth={20}
      indicator={true}
      onItemsChanged={preSetItems}
      TreeItemComponent={ContextComponent}
    />
  );
};

const ContextSortableTree = function (props) {

  return (
    <div style={{ minHeight: 400 }}>
      <MinimalViable />
      <ContextModal />
    </div>
  );
}

export default ContextSortableTree;
