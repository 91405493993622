import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import { deleteGroup, newGroup, requestGroups, updateGroup } from '../actions';

const useStyles = makeStyles(theme => ({
  card: {
    height: 140,
    width: 200,
    minWidth: 500
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const columns = [
  { field: 'id', headerName: 'ID', width: 150 },
  { field: 'name', headerName: 'name', width: 300, editable: true },
  { headerName: 'delete', width: 50 },
];

// function validateEmail(email) {
//   const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// }

const GroupContainer = function (props) {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const currentGroups = useSelector((state) => state.groups.groups);
  const newGroupRow = {
    id: 'new'
  };
  const groups = [newGroupRow, ...currentGroups] || [];
  const [lastEditRowsModel, setLastEditRowsModel] = React.useState({});
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [lastSelectedRow, setLastSelectedRow] = React.useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);

  useEffect(() => {
    dispatch(requestGroups());
  }, [dispatch]);

  const handleRowClick = useCallback((params) => {
    setLastSelectedRow(params.id)
  }, []);

  const handleSelectionModelChange = useCallback((params) => {
    setSelectedRows(params)
  }, []);

  const triggerDelete = function () {
    dispatch(deleteGroup({ id: selectedRows[0] }));
  };

  const handleEditRowsModelChange = useCallback((model) => {
    if (Object.entries(model).length === 0) {
      //place to commit last edit
      const hasNew = lastEditRowsModel.hasOwnProperty('new');
      const hasName = hasNew && lastEditRowsModel['new'].hasOwnProperty('name') && lastEditRowsModel['new'].name.value !== '';
      const validParams = hasName;
      if (hasNew && validParams) {
        const group = {
          name: lastEditRowsModel['new'].name.value
        };
        dispatch(newGroup(group));
        setEditRowsModel({ ...model });
      }
      else {
        const id = Object.keys(lastEditRowsModel)[0];
        const group = {
          id: id,
          name: lastEditRowsModel[id].name.value
        };
        dispatch(updateGroup(group));
        setEditRowsModel({ ...model });
      }
    }
    else {
      setEditRowsModel({ ...model });
      setLastEditRowsModel({ ...model });
    }
  }, [dispatch, lastEditRowsModel]);

  return (
    <Paper>
      <Alert severity="info" style={{ marginTop: 8 }}>
        <code>editRowsModel: {JSON.stringify(editRowsModel)}</code>
        <br />
        <code> lastEditRowsModel: {JSON.stringify(lastEditRowsModel)}</code>
        <br />
        <code> last selected row: {JSON.stringify(lastSelectedRow)}</code>
        <br />
      </Alert>
      {selectedRows.length > 0 &&
        <IconButton
          variant='contained'
          color='secondary'
          className={useStyles.button}
          onClick={triggerDelete}
          size="small">
          <DeleteIcon />
        </IconButton>
      }
      <Paper sx={{ height: 300, width: '100%' }}>
        <DataGrid
          editMode="row"
          rows={groups}
          columns={columns}
          editRowsModel={editRowsModel}
          onEditRowsModelChange={handleEditRowsModelChange}
          onRowClick={handleRowClick}
          onSelectionModelChange={handleSelectionModelChange}
          apiRef={apiRef}
        />
      </Paper>
    </Paper>
  );
};

export default GroupContainer;
