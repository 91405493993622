
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';


import { closeModal } from '../actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ContextModal = () => {
  const dispatch = useDispatch();
  const [cx, setCx] = useState({})

  const modalOpen = useSelector((state) => state.context.modalOpen);
  const contextById = useSelector((state) => state.context.byId);
  const modalId = useSelector((state) => state.context.modalId);

  const handleClose = () => {
    dispatch(closeModal());
  }
  const addPropertyClick = () => {

  }
  useEffect(() => {
    if(contextById && modalId && contextById[modalId]) {
      setCx(contextById[modalId]);
    }
  }, [dispatch, contextById, modalId])
  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {cx ? cx.text : 'No context selected'}
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {cx ? cx.id : ''}
          </Typography>
          <Box sx={{display:'flex'}}>
            <Box sx={{flex: 1, flexShrink: 0, margin: '0 5px', padding: '5px'}}>
              <h3>Children</h3>
              <List sx={{ padding: '2px' }}>
                {
                    cx && cx.children && cx.children.length &&
                    cx.children.map((child) => {
                      const childCx = (contextById[child]) ? contextById[child].text : '';
                      const childCxId = (contextById[child]) ? contextById[child].id : '';
                      return (
                      <ListItem>
                        <ListItemText primary={childCx + ':' + childCxId} />
                      </ListItem>
                    )})
                  }
                <Divider />
              </List>
              <Box sx={{display:'flex'}}>
                <h3>Properties</h3>
                <IconButton
                  sx={{ display: 'flex' }}
                  variant='contained'
                  color='secondary'
                  onClick={addPropertyClick}
                  size="small">
                  <AddIcon />
                </IconButton>
              </Box>
              <List sx={{ border: '1px solid rgba(0, 0, 0, 0.87)', padding: '5px' }}>
                <ListItem disablePadding>
                </ListItem>
                <Divider />
              </List>
            </Box>
            <Box sx={{flex: 1, flexShrink: 0, margin: '0 5px', padding: '5px'}}>
              <List>
                <ListItem>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Type"
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
                <ListItem>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Root Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Root Type"
                    >
                      <MenuItem value={'thread'}>Thread</MenuItem>
                      <MenuItem value={'item'}>Item</MenuItem>
                    </Select>
                  </FormControl>
                </ListItem>
              </List>
              <h3>Rules</h3>
              <List sx={{ padding: '5px' }}>
                <ListItem disablePadding>
                  <ListItemText primary="" />
                </ListItem>
                <Divider />
              </List>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default ContextModal;