
import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_UN_UPDATE,
  LOGIN_PW_UPDATE
} from './actions';

function login(
  state = {
    isFetching: false,
    login: [],
    username: '',
    password: '',
    loggedIn: false,
    token: null
  },
  action
) {
  switch (action.type) {
    case LOGIN_UN_UPDATE:
      return Object.assign({}, state, {
        username: action.username
      });
    case LOGIN_PW_UPDATE:
      return Object.assign({}, state, {
        password: action.password
      });
    case LOGIN_BEGIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        loggedIn: true,
        token: action.jwt.token
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        loggedIn: false
      });
    default:
      return state;
  }
}

export default login;
