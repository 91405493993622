import habitApi from '../../root/habitApi';

export const REQUEST_GROUP_BEGIN = 'REQUEST_GROUP_BEGIN';
export const REQUEST_GROUP_SUCCESS = 'REQUEST_GROUP_SUCCESS';
export const REQUEST_GROUP_FAILURE = 'REQUEST_GROUP_FAILURE';

export const REQUEST_GROUPS_BEGIN = 'REQUEST_GROUPS_BEGIN';
export const REQUEST_GROUPS_SUCCESS = 'REQUEST_GROUPS_SUCCESS';
export const REQUEST_GROUPS_FAILURE = 'REQUEST_GROUPS_FAILURE';

export const NEW_GROUP_BEGIN = 'NEW_GROUP_BEGIN';
export const NEW_GROUP_SUCCESS = 'NEW_GROUP_SUCCESS';
export const NEW_GROUP_FAILURE = 'NEW_GROUP_FAILURE';

export const UPDATE_GROUP_BEGIN = 'UPDATE_GROUP_BEGIN';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';

export const DELETE_GROUP_BEGIN = 'DELETE_GROUP_BEGIN';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function requestGroup() {
  return dispatch => {
    dispatch(requestGroupBegin());
    return habitApi.endpoints.group.getOne({ id: "" })
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(requestGroupSuccess(json));
        return json;
      })
      .catch(error => dispatch(requestGroupFailure(error)));
  };

  function requestGroupBegin() { return { type: REQUEST_GROUP_BEGIN }; }
  function requestGroupSuccess(group) { return { type: REQUEST_GROUP_SUCCESS, group }; }
  function requestGroupFailure(error) { return { type: REQUEST_GROUP_FAILURE, error }; }

}

export function requestGroups() {
  return dispatch => {
    dispatch(requestGroupsBegin());
    return habitApi.endpoints.groups.getAll()
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(requestGroupsSuccess(json));
        return json;
      })
      .catch(error => dispatch(requestGroupsFailure(error)));
  };

  function requestGroupsBegin() { return { type: REQUEST_GROUPS_BEGIN }; }
  function requestGroupsSuccess(groups) { return { type: REQUEST_GROUPS_SUCCESS, groups }; }
  function requestGroupsFailure(error) { return { type: REQUEST_GROUPS_FAILURE, error }; }

}

export function deleteGroup(group) {
  return dispatch => {
    dispatch(deleteGroupBegin());
    return habitApi.endpoints.groups.delete(group)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(deleteGroupSuccess(group));
        return json;
      })
      .catch(error => dispatch(deleteGroupFailure(error)));
  };
  function deleteGroupBegin() { return { type: DELETE_GROUP_BEGIN }; }
  function deleteGroupSuccess(group) { return { type: DELETE_GROUP_SUCCESS, group }; }
  function deleteGroupFailure(error) { return { type: DELETE_GROUP_FAILURE, error: error }; }
}

export function updateGroup(group) {
  return dispatch => {
    dispatch(updateGroupBegin());
    return habitApi.endpoints.groups.update(group)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(updateGroupSuccess(json));
        return json;
      })
      .catch(error => dispatch(updateGroupFailure(error)));
  };

  function updateGroupBegin() { return { type: UPDATE_GROUP_BEGIN }; }
  function updateGroupSuccess(group) { return { type: UPDATE_GROUP_SUCCESS, group }; }
  function updateGroupFailure(error) { return { type: UPDATE_GROUP_FAILURE, error: error }; }

}

export function newGroup(group) {
  return dispatch => {
    dispatch(newGroupBegin());
    return habitApi.endpoints.groups.create(group)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(newGroupSuccess(json));
        return json;
      })
      .catch(error => dispatch(newGroupFailure(error)));
  };

  function newGroupBegin() { return { type: NEW_GROUP_BEGIN }; }
  function newGroupSuccess(json) { return { type: NEW_GROUP_SUCCESS, newGroup: json }; }
  function newGroupFailure(error) { return { type: NEW_GROUP_FAILURE, error: error }; }
}
