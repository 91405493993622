import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Thread from './Thread';
import ThreadContainer from './ThreadContainer';
import { deleteThread, newThread, requestThreads, updateThread } from '../actions.js';
import { requestUser } from '../../users/actions.js';
import { deleteItem, newItem, requestItems, updateItem } from '../../item/actions.js';

const useStyles = makeStyles(theme => ({
  card: {
    height: 140,
    width: 200,
    minWidth: 500
  },
  root: {
    flexGrow: 1
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  }
}));

const AllThreadsContainer = function () {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items.items);
  const threads = useSelector((state) => state.threads.threads);

  useEffect(() => {
    dispatch(requestThreads());
    dispatch(requestUser());
    dispatch(requestItems());
  }, [dispatch]);

  useEffect(() => {
  }, [items, threads]);

  const threadsById = useSelector((state) => state.threads.byId);
  const activeUser = useSelector((state) => state.users.me);
  const activeThread = (threadsById && activeUser) ? threadsById[activeUser.rootThreadId] : null;

  function dispatchNewThread(inputText, incomingThreadId) {
    const threadId = (incomingThreadId) ? incomingThreadId : (activeThread) ? activeThread.id : null;
    dispatch(newThread({ text: inputText })).then((thread) => {
      if (thread) {
        let currThread = threadsById[threadId];
        if (!currThread.children.hasOwnProperty('threads')) {
          currThread.children.threads = [];
        }
        currThread.children.threads.push(thread.id);
        dispatch(updateThread(currThread));
      }
    }
    );
  }

  function dispatchDeleteThread(threadId) {
    dispatch(deleteThread({ id: threadId })).then(() => {
      activeThread.children.threads.reduce((threads, id) => {
        if (id !== threadId) {
          threads.push(id);
        }
        return threads;
      }, []);
      dispatch(updateThread(activeThread));
    });
  }

  function dispatcUpdateThread(threadId, threadText) {
    dispatch(updateThread({ id: threadId, text: threadText }));
  }

  function dispatchNewItem(itemText, threadId) {
    return dispatch(newItem({ text: itemText })).then((item) => {
      const thread = threadsById[threadId];
      thread.children.items.push(item.id);
      dispatch(updateThread(thread))
    });
  }

  function dispatchDeleteItem(itemId) {
    dispatch(deleteItem({ id: itemId }));
  }

  function dispatchUpdateItem(itemId, itemText) {
    dispatch(updateItem({ id: itemId, text: itemText }));
  }

  return (
    <Container maxWidth='lg'>
      <Grid container className={useStyles.root} direction='column' aligncontent='flex-start'>
        <Card className={useStyles.card} sx={{ mb: 1, p: 1 }}>
          <Thread onChange={dispatchNewThread} new />
        </Card>
        {activeThread && activeThread.children && activeThread.children.threads &&
          activeThread.children.threads.map((threadId) => (
            threadId && threadsById.hasOwnProperty(threadId) &&
              <ThreadContainer
                key={threadId + ''}
                threadId={threadId + ''}
                newItem={{ text: '' }}
                onNewThread={dispatchNewThread}
                onChange={dispatcUpdateThread}
                onDelete={dispatchDeleteThread}
                onNewItem={dispatchNewItem}
                onChangeItem={dispatchUpdateItem}
                onDeleteItem={dispatchDeleteItem}
              />
          ))}
      </Grid>
    </Container>
  );
}

AllThreadsContainer.propTypes = {
};

export default AllThreadsContainer;
