import React from 'react';
import Paper from '@mui/material/Paper';
import ContextSortableTree from './ContextSortableTree';

import './context.css';

const ContextContainer = function (props) {
  return (
    <>
      <Paper>
        <ContextSortableTree />
      </Paper>
      {/* <ContextGrid /> */}
    </>
  );
};

export default ContextContainer;
