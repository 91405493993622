import {
  NEW_CONTEXT_BEGIN,
  NEW_CONTEXT_SUCCESS,
  NEW_CONTEXT_FAILURE,
  DELETE_CONTEXT_BEGIN,
  DELETE_CONTEXT_SUCCESS,
  DELETE_CONTEXT_FAILURE,
  UPDATE_CONTEXT_BEGIN,
  UPDATE_CONTEXT_SUCCESS,
  UPDATE_CONTEXT_FAILURE,
  REQUEST_CONTEXT_BEGIN,
  REQUEST_CONTEXT_SUCCESS,
  REQUEST_CONTEXT_FAILURE,
  OPEN_MODAL,
  CLOSE_MODAL
} from './actions';

const hashToTree = (root, data) => {
  let children = [];
  if (!root || !root?.children?.length) {
    return children;
  }

  children = root.children
    .filter(ob => data[ob] !== undefined) //filter out non-existant IDs
    .map(ob => {
      let newOb = { ...data[ob] };
      newOb['value'] = newOb.text;

      let newChildren = [];
      if (newOb?.children?.length) {
        newChildren = hashToTree(newOb, data);
      }

      newOb.children = newChildren;

      return newOb;
    })

  return children;
};

export const sortableTree = (contextData) => {

  const root = { ...contextData.find(ob => ob.id === 'root') };
  const contextHash = contextData.reduce(function (map, obj) {
    map[obj.id] = obj;
    return map;
  }, {})

  if (root) {
    root.children = hashToTree(root, contextHash);
    root['value'] = root.text
    return [root]
  }

  return [];
}

function context(
  state = {
    isDeleting: false,
    isFetching: false,
    isUpdating: false,
    isCreatingNew: false,
    context: [],
    byId: {},
    byName: {},
    allIds: [],
    sortableData: [],
    modalOpen: false,
    modalId: null
  },
  action
) {
  switch (action.type) {
    case UPDATE_CONTEXT_BEGIN:
      return Object.assign({}, state, {
        isUpdating: true
      });
    case UPDATE_CONTEXT_SUCCESS:
      const updatedContext = state.context.map((obj) => {
        if (action.context.id !== obj.id) {
          return obj;
        }
        return { ...action.context, text: action.context.text };
      });
      const keys = Object.keys(state.byId);
      const names = Object.keys(state.byName);
      return Object.assign({}, state, {
        isUpdating: false,
        context: updatedContext,
        byId: keys.reduce(function (map, obj) {
          if (obj === action.context.id) {
            map[action.context.id] = action.context;
          } else {
            map[obj] = state.byId[obj];
          }
          return map;
        }, {}),
        byName: names.reduce(function (map, obj) {
          if (obj === action.context.text) {
            map[action.context.text] = action.context;
          } else {
            map[obj] = state.byId[obj];
          }
          return map;
        }, {}),
        sortableData: sortableTree(updatedContext)
      });
    case UPDATE_CONTEXT_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false
      });
    case NEW_CONTEXT_BEGIN:
      return Object.assign({}, state, {
        isCreatingNew: true
      });
    case NEW_CONTEXT_SUCCESS:
      const addedContext = [action.newContext, ...state.context];
      return Object.assign({}, state, {
        isCreatingNew: false,
        context: addedContext,
        byId: [action.newContext, ...state.context].reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        byName: [action.newContext, ...state.context].reduce(function (map, obj) {
          map[obj.text] = obj;
          return map;
        }, {}),
        allIds: [action.newContext, ...state.context].map(obj => obj.id),
        newContext: { text: '' },
        sortableData: sortableTree(addedContext)
      });
    case NEW_CONTEXT_FAILURE:
      return Object.assign({}, state, {
        isCreatingNew: false,
        context: []
      });
    case DELETE_CONTEXT_BEGIN:
      return Object.assign({}, state, {
        isDeleting: true
      });
    case DELETE_CONTEXT_SUCCESS:
      const deletedContext = state.context.filter(obj => obj.id !== action.context.id);
      return Object.assign({}, state, {
        isDeleting: false,
        context: deletedContext,
        byId: state.allIds.filter(obj => obj !== action.context.id)
          .reduce(function (map, obj) {
            map[obj] = state.byId[obj];
            return map;
          }, {}),
        allIds: state.allIds.filter(obj => obj !== action.context.id),
        newContext: { text: '' },
        sortableData: sortableTree(deletedContext)
      });
    case DELETE_CONTEXT_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        context: []
      });
    case REQUEST_CONTEXT_BEGIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REQUEST_CONTEXT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        context: action.context,
        byId: action.context.reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        byName: action.context.reduce(function (map, obj) {
          map[obj.text] = obj;
          return map;
        }, {}),
        allIds: action.context.map(obj => obj.id),
        sortableData: sortableTree(action.context)
      });
    case REQUEST_CONTEXT_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        context: []
      });
      case OPEN_MODAL:
        return Object.assign({}, state, {
          modalOpen: true,
          modalId: action.modalContextId
        });
      case CLOSE_MODAL:
          return Object.assign({}, state, {
            modalOpen: false,
            modalId: null
          });
    default:
      return state;
  }
}

export default context;
