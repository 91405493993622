import {
  UPDATE_ITEM_BEGIN,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAILURE,
  NEW_ITEM_BEGIN,
  NEW_ITEM_SUCCESS,
  NEW_ITEM_FAILURE,
  DELETE_ITEM_BEGIN,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  REQUEST_ITEMS_BEGIN,
  REQUEST_ITEMS_SUCCESS,
  REQUEST_ITEMS_FAILURE
} from './actions';

function items(
  state = {
    isDeleting: false,
    isFetching: false,
    isUpdating: false,
    isCreatingNew: false,
    items: [],
    byId: {},
    allIds: [],
    newItem: { text: '' }
  },
  action
) {
  switch (action.type) {
    case UPDATE_ITEM_BEGIN:
      return Object.assign({}, state, {
        isUpdating: true
      });
    case UPDATE_ITEM_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        items: state.items.map((obj) => {
          if (action.item.id !== obj.id) {
            return obj;
          }
          return { ...action.item, text: action.item.text };
        }),
        byId: Object.keys(state.byId).reduce(function (map, obj) {
          if (obj.id === action.item.id) {
            map[action.item.id] = action.item;
          } else {
            map[obj.id] = obj;
          }
          return map;
        }, state.byId)
      });
    case UPDATE_ITEM_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false
      });
    case NEW_ITEM_BEGIN:
      return Object.assign({}, state, {
        isCreatingNew: true
      });
    case NEW_ITEM_SUCCESS:
      return Object.assign({}, state, {
        isCreatingNew: false,
        items: [action.newItem, ...state.items],
        byId: [action.newItem, ...state.items].reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        allIds: [action.newItem, ...state.items].map(obj => obj.id),
        newItem: { text: '' }
      });
    case NEW_ITEM_FAILURE:
      return Object.assign({}, state, {
        isCreatingNew: false,
        items: []
      });
    case DELETE_ITEM_BEGIN:
      return Object.assign({}, state, {
        isDeleting: true
      });
    case DELETE_ITEM_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        items: state.items.filter(obj => obj.id !== action.item.id),
        byId: state.allIds.filter(obj => obj !== action.item.id)
          .reduce(function (map, obj) {
            map[obj] = state.byId[obj];
            return map;
          }, {}),
        allIds: state.allIds.filter(obj => obj !== action.item.id),
        newItem: { text: '' }
      });
    case DELETE_ITEM_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        items: []
      });
    case REQUEST_ITEMS_BEGIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REQUEST_ITEMS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.items,
        byId: action.items.reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        allIds: action.items.map(obj => obj.id)
      });
    case REQUEST_ITEMS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        items: []
      });
    default:
      return state;
  }
}

export default items;
