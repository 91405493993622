import habitApi from '../../root/habitApi';

export const REQUEST_TAGS_BEGIN = 'REQUEST_TAGS_BEGIN';
export const REQUEST_TAGS_SUCCESS = 'REQUEST_TAGS_SUCCESS';
export const REQUEST_TAGS_FAILURE = 'REQUEST_TAGS_FAILURE';

export const NEW_TAG_BEGIN = 'NEW_TAG_BEGIN';
export const NEW_TAG_SUCCESS = 'NEW_TAG_SUCCESS';
export const NEW_TAG_FAILURE = 'NEW_TAG_FAILURE';

export const UPDATE_TAG_BEGIN = 'UPDATE_TAG_BEGIN';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';
export const UPDATE_TAG_FAILURE = 'UPDATE_TAG_FAILURE';

export const DELETE_TAG_BEGIN = 'DELETE_TAG_BEGIN';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';
export const DELETE_TAG_FAILURE = 'DELETE_TAG_FAILURE';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function requestTags() {
  return dispatch => {
    dispatch(requestTagsBegin());
    return habitApi.endpoints.tags.getAll()
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(requestTagsSuccess(json));
        return json;
      })
      .catch(error => dispatch(requestTagsFailure(error)));
  };

  function requestTagsBegin() { return { type: REQUEST_TAGS_BEGIN }; }
  function requestTagsSuccess(tags) { return { type: REQUEST_TAGS_SUCCESS, tags }; }
  function requestTagsFailure(error) { return { type: REQUEST_TAGS_FAILURE, error }; }

}

export function deleteTag(tag) {
  return dispatch => {
    dispatch(deleteTagBegin());
    return habitApi.endpoints.tags.delete(tag)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(deleteTagSuccess(tag));
        return json;
      })
      .catch(error => dispatch(deleteTagFailure(error)));
  };
  function deleteTagBegin() { return { type: DELETE_TAG_BEGIN }; }
  function deleteTagSuccess(tag) { return { type: DELETE_TAG_SUCCESS, tag }; }
  function deleteTagFailure(error) { return { type: DELETE_TAG_FAILURE, error: error }; }
}

export function updateTag(tag) {
  return dispatch => {
    dispatch(updateTagBegin());
    return habitApi.endpoints.tags.update(tag)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(updateTagSuccess(json));
        return json;
      })
      .catch(error => dispatch(updateTagFailure(error)));
  };

  function updateTagBegin() { return { type: UPDATE_TAG_BEGIN }; }
  function updateTagSuccess(tag) { return { type: UPDATE_TAG_SUCCESS, tag }; }
  function updateTagFailure(error) { return { type: UPDATE_TAG_FAILURE, error: error }; }

}

export function newTag(tag) {
  return dispatch => {
    dispatch(newTagBegin());
    return habitApi.endpoints.tags.create(tag)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(newTagSuccess(json));
        return json;
      })
      .catch(error => dispatch(newTagFailure(error)));
  };

  function newTagBegin() { return { type: NEW_TAG_BEGIN }; }
  function newTagSuccess(json) { return { type: NEW_TAG_SUCCESS, newTag: json }; }
  function newTagFailure(error) { return { type: NEW_TAG_FAILURE, error: error }; }
}
