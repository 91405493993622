import {
  NEW_TAG_BEGIN,
  NEW_TAG_SUCCESS,
  NEW_TAG_FAILURE,
  DELETE_TAG_BEGIN,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAILURE,
  UPDATE_TAG_BEGIN,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAILURE,
  REQUEST_TAGS_BEGIN,
  REQUEST_TAGS_SUCCESS,
  REQUEST_TAGS_FAILURE,
} from './actions';

function tags(
  state = {
    isDeleting: false,
    isFetching: false,
    isUpdating: false,
    isCreatingNew: false,
    tags: [],
    byId: {},
    allIds: []
  },
  action
) {
  switch (action.type) {
    case UPDATE_TAG_BEGIN:
      return Object.assign({}, state, {
        isUpdating: true
      });
    case UPDATE_TAG_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        tags: state.tags.map((obj) => {
          if (action.tag.id !== obj.id) {
            return obj;
          }
          return { ...action.tag, text: action.tag.text };
        }),
        byId: Object.keys(state.byId).reduce(function (map, obj) {
          if (obj.id === action.tag.id) {
            map[action.tag.id] = action.tag;
          } else {
            map[obj.id] = obj;
          }
          return map;
        }, {})
      });
    case UPDATE_TAG_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false
      });
    case NEW_TAG_BEGIN:
      return Object.assign({}, state, {
        isCreatingNew: true
      });
    case NEW_TAG_SUCCESS:
      return Object.assign({}, state, {
        isCreatingNew: false,
        tags: [action.newTag, ...state.tags],
        byId: [action.newTag, ...state.tags].reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        allIds: [action.newTag, ...state.tags].map(obj => obj.id),
        newTag: { text: '' }
      });
    case NEW_TAG_FAILURE:
      return Object.assign({}, state, {
        isCreatingNew: false,
        tags: []
      });
    case DELETE_TAG_BEGIN:
      return Object.assign({}, state, {
        isDeleting: true
      });
    case DELETE_TAG_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        tags: state.tags.filter(obj => obj.id !== action.tag.id),
        byId: state.allIds.filter(obj => obj !== action.tag.id)
          .reduce(function (map, obj) {
            map[obj] = state.byId[obj];
            return map;
          }, {}),
        allIds: state.allIds.filter(obj => obj !== action.tag.id),
        newTag: { text: '' }
      });
    case DELETE_TAG_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        tags: []
      });
    case REQUEST_TAGS_BEGIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REQUEST_TAGS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        tags: action.tags,
        byId: action.tags.reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        allIds: action.tags.map(obj => obj.id)
      });
    case REQUEST_TAGS_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        tags: []
      });
    default:
      return state;
  }
}

export default tags;
