import fetch from 'cross-fetch';
import { requestUser } from '../users/actions';

export const LOGIN_BEGIN = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_UN_UPDATE = 'LOGIN_UN_UPDATE';
export const LOGIN_PW_UPDATE = 'LOGIN_PW_UPDATE';


const BUILD_ENV = process.env.REACT_APP_BUILD_ENV || "local";

const API_URL = (BUILD_ENV === "local") ? 'http://localhost:3000/dev/login' : 'https://yt3uufqhna.execute-api.us-east-1.amazonaws.com/dev/login';

export function login(username, password) {

  const config = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
    body: JSON.stringify({ username, password })
  };

  return dispatch => {
    dispatch(loginBegin());
    return fetch(API_URL, config)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        window.localStorage.setItem('token', json.token);
        dispatch(requestUser());
        dispatch(loginSuccess(json));

        return json;
      })
      .catch(error => dispatch(loginFailure(error)));
  };
}

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

function loginBegin() {
  return {
    type: LOGIN_BEGIN
  };
}

function loginSuccess(jwt) {
  return {
    type: LOGIN_SUCCESS,
    jwt
  };
}

function loginFailure(error) {
  window.localStorage.setItem('token', null);
  return {
    type: LOGIN_FAILURE,
    error: error
  };
}

export function updateUsername(username) {
  return {
    type: LOGIN_UN_UPDATE,
    username
  }
}

export function updatePassword(password) {
  return {
    type: LOGIN_PW_UPDATE,
    password
  }
}