import {
  NEW_USER_BEGIN,
  NEW_USER_SUCCESS,
  NEW_USER_FAILURE,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER_BEGIN,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  REQUEST_USERS_BEGIN,
  REQUEST_USERS_SUCCESS,
  REQUEST_USERS_FAILURE,
  REQUEST_USER_BEGIN,
  REQUEST_USER_SUCCESS,
  REQUEST_USER_FAILURE,
} from './actions';

function users(
  state = {
    isDeleting: false,
    isFetching: false,
    isUpdating: false,
    isCreatingNew: false,
    users: [],
    me: null,
    byId: {},
    allIds: [],
    activeUser: true
  },
  action
) {
  switch (action.type) {
    case UPDATE_USER_BEGIN:
      return Object.assign({}, state, {
        isUpdating: true
      });
    case UPDATE_USER_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        users: state.users.map((obj) => {
          if (action.user.id !== obj.id) {
            return obj;
          }
          return { ...action.user, text: action.user.text };
        }),
        byId: Object.keys(state.byId).reduce(function (map, obj) {
          if (obj.id === action.user.id) {
            map[action.user.id] = action.user;
          } else {
            map[obj.id] = obj;
          }
          return map;
        }, {})
      });
    case UPDATE_USER_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false
      });
    case NEW_USER_BEGIN:
      return Object.assign({}, state, {
        isCreatingNew: true
      });
    case NEW_USER_SUCCESS:
      return Object.assign({}, state, {
        isCreatingNew: false,
        users: [action.newUser, ...state.users],
        byId: [action.newUser, ...state.users].reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        allIds: [action.newUser, ...state.users].map(obj => obj.id),
        newUser: { text: '' }
      });
    case NEW_USER_FAILURE:
      return Object.assign({}, state, {
        isCreatingNew: false,
        users: []
      });
    case DELETE_USER_BEGIN:
      return Object.assign({}, state, {
        isDeleting: true
      });
    case DELETE_USER_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        users: state.users.filter(obj => obj.id !== action.user.id),
        byId: state.allIds.filter(obj => obj !== action.user.id)
          .reduce(function (map, obj) {
            map[obj] = state.byId[obj];
            return map;
          }, {}),
        allIds: state.allIds.filter(obj => obj !== action.user.id),
        newUser: { text: '' }
      });
    case DELETE_USER_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        users: []
      });
    case REQUEST_USERS_BEGIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REQUEST_USERS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        users: action.users,
        byId: action.users.reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        allIds: action.users.map(obj => obj.id)
      });
    case REQUEST_USERS_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        users: []
      });
    case REQUEST_USER_BEGIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REQUEST_USER_SUCCESS:
      const activeUser = (action.user) ? true : false;
      return Object.assign({}, state, {
        isFetching: false,
        me: action.user,
        activeUser
      });
    case REQUEST_USER_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        me: {},
        activeUser: false
      });
    default:
      return state;
  }
}

export default users;
