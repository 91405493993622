import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Task from './Task';

import { updateThread } from '../../thread/actions';

const ENTER_KEY = 13;
const Project = function (props) {
  const dispatch = useDispatch();
  const [id] = useState((props.id) ? props.id : null);
  const [nameIsEditable, setNameIsEditable] = useState(false);
  const thisProject = useSelector((state) => {
    if(state.threads.byId && state.threads.byId[id]) {
      return state.threads.byId[id];
    }
    return null;
  });
  const [projectName, setProjectName] = useState(thisProject && thisProject.text ? thisProject.text : '');

  const nameClick = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      console.log(id);
      setNameIsEditable(!nameIsEditable);
    }
  };

  function stopPropagation(e) {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
  }

  const onBlur = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      thisProject.text = projectName;
      dispatch(updateThread(thisProject));
      setNameIsEditable(!nameIsEditable);
      setNameIsEditable(false);
    }
  }

  function handleChange(e) {
    const newValue = e.target.value;
    thisProject.text = newValue;
    setProjectName(newValue);
  }

  const onKeyDown = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      if (e.keyCode === ENTER_KEY) {
        thisProject.text = projectName;
        dispatch(updateThread(thisProject));
        setNameIsEditable(!nameIsEditable);
      }
    }
  }

  return (
    <Grid item xs={6} md={4}>
      <Card>
        <CardContent>
          {!nameIsEditable ? 
          <Typography
          variant="h5"
          onClick={nameClick}>
            {projectName}
          </Typography> 
          :
          <Box>
            <TextField
              value={projectName}
              label={props.new ? 'Context Name' : ''}
              size="small"
              onBlur={onBlur}
              onClick={stopPropagation}
              onChange={handleChange}
              onKeyDown={onKeyDown}
              variant='standard' />
          </Box>
          }
          <Typography
          variant="p"
          onClick={nameClick}>
            {thisProject && thisProject.id ? thisProject.id : ''}
          </Typography> 
          <Box>
            <Task name="do something" completed={true}/>
            <Task />
            <Task />
            <Task />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Project;
