import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import Collapse from '@mui/material/Collapse';

import { newThread, updateThread } from '../actions';

import Item from '../../item/components/Item';
import SubThreadContainer from './SubThreadContainer';
import Thread from './Thread';

const useStyles = makeStyles(theme => ({
  card: {
    height: 50,
    width: 200,
    minWidth: 500
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const ThreadContainer = function (props) {
  const dispatch = useDispatch();

  const [containerOpen, setContainerOpen] = useState(false);
  const threadsById = useSelector((state) => state.threads.byId ? state.threads.byId : []);
  const itemsById = useSelector((state) => state.items.byId ? state.items.byId : []);
  const currThread = (threadsById.hasOwnProperty(props.threadId)) ? threadsById[props.threadId] : undefined;
  const currItems = (currThread && currThread.children && currThread.children.items) ? currThread.children.items : [];

  function toggleContainer(open) {
    setContainerOpen(open);
  }

  function dispatchUpdateThread(threadId, threadText) {
    dispatch(updateThread({ id: threadId, text: threadText }));
  }

  function triggerDelete() {
    props.onDelete(props.threadId); // deletes this thread
  }

  function dispatchNewThread(inputText) {
    dispatch(newThread({ text: inputText })).then((newThread) => {
      if (newThread) {
        if (!currThread.children.hasOwnProperty('threads')) {
          currThread.children['threads'] = [];
        }
        currThread.children.threads.push(newThread.id);
        dispatch(updateThread(currThread));
      }
    }
    );
  }

  if (currThread !== undefined) {
    return (
      <Card className={useStyles.card} sx={{ mb: 1, p: 1 }}>
        <Thread
          thread={currThread}
          toggleContainer={toggleContainer}
          onChange={dispatchUpdateThread}
          onDelete={triggerDelete}
          open={containerOpen}
        />
        <Collapse in={containerOpen}>
          <Card>
            <Divider textAlign="left" sx={{ mt: 1, typography: 'caption' }}>threads</Divider>
            <Thread key={currThread.id + 'newThread'}
              onChange={dispatchNewThread}
              thread={{ text: '' }}
              open={containerOpen}
              new />
            {props.threadId && threadsById.hasOwnProperty(props.threadId) &&
              <SubThreadContainer threadId={props.threadId}
                onNewItem={props.onNewItem}
                onChangeItem={props.onChangeItem}
                onDeleteItem={props.onDeleteItem}
                key={props.threadId}
                threadsById={threadsById} />
            }
            <Divider textAlign="left" sx={{ mt: 1, typography: 'caption' }}>items</Divider>
            {!props.new &&
              <Item key={currThread.id + 'newItem'} onChange={props.onNewItem} threadId={currThread.id} item={props.newItem} new />

            }
            {!props.new && currItems.map((itemId) => {
              const tmpItem = itemsById[itemId];
              return (
                tmpItem &&
                <Item item={tmpItem} key={tmpItem.id} threadId={currThread.id}
                  onChange={props.onChangeItem}
                  onDelete={props.onDeleteItem} />
              )
            })
            }
          </Card>
        </Collapse>
      </Card>
    );
  }
  else {
    console.log('no currThread ' + props.threadId);
    return <div />;
  }
}

ThreadContainer.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onNewItem: PropTypes.func,
  onNewThread: PropTypes.func,
  onChangeItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  newItem: PropTypes.object,
  new: PropTypes.bool,
  threadId: PropTypes.string
};

export default ThreadContainer;
