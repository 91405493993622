import fetch from 'cross-fetch';

function kebabCaseToCamel(str) {
  return str.replace(/(-\w)/g, (matches) => matches[1].toUpperCase());
}

const BUILD_ENV = process.env.REACT_APP_BUILD_ENV || "local";

const API_URL = (BUILD_ENV === "local") ? 'http://localhost:3000/dev' : 'https://yt3uufqhna.execute-api.us-east-1.amazonaws.com/dev';

class HabitApi {
  constructor({ url }) {
    this.url = url;
    this.endpoints = {};
    this.config = {
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      //body: JSON.stringify(thread)
    };
    this.token = window.localStorage.getItem('token');
    this.checkToken = this.checkToken.bind(this);
    this.createEndpoints = this.createEndpoints.bind(this);
  }

  handleErrors(response) {
    if (!response.ok) {
      window.localStorage.setItem('token', null);
      console.log(response.status);
      throw Error(response.statusText);
    }
    return response;
  }

  getAuthConfig() {
    return Object.assign({}, this.config, {
      headers: {
        Authorization: 'Bearer ' + window.localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    });
  }

  checkToken() {
    if (!this.token || this.token === null) {
      this.token = window.localStorage.getItem('token');
    }
  }

  /**
   * Create and store a single entity's endpoints
   * @param {A entity Object} entity
   */
  createEntity(entity) {
    /**
     * If there is a - in the entity.name, then change it 
     * to camelCase. E.g 
     * ```
     * myApi.createEntity({ name : 'foo-bar'})
     * myApi.endpoints.fooBar.getAll(...)
     */

    const name = kebabCaseToCamel(entity);
    this.endpoints[name] = this.createEndpoints({ name: entity });
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this))
  }

  createEndpoints({ name }) {
    var endpoints = {};

    const resourceURL = `${this.url}/${name}`

    endpoints.getAll = () => {
      return fetch(resourceURL, this.getAuthConfig());
    };

    endpoints.getOne = ({ id }) => fetch(`${resourceURL}/${id}`, this.getAuthConfig())

    endpoints.create = (toCreate) => {
      let config = Object.assign({}, this.getAuthConfig(), {
        method: 'POST',
        body: JSON.stringify(toCreate)
      });
      return fetch(resourceURL, config)
    };

    endpoints.update = (toUpdate) => {
      let config = Object.assign({}, this.getAuthConfig(), {
        method: 'PUT',
        body: JSON.stringify(toUpdate)
      });
      return fetch(`${resourceURL}/${toUpdate.id}`, config)
    };

    endpoints.delete = (toDelete) => {
      let config = Object.assign({}, this.getAuthConfig(), {
        method: 'DELETE',
        body: JSON.stringify(toDelete)
      });
      return fetch(`${resourceURL}/${toDelete.id}`, config);
    }

    return endpoints
  }

};

const habitApi = new HabitApi({ url: API_URL });
habitApi.createEntities(['threads', 'items', 'user', 'users', 'tags', 'context', 'groups']);

export default habitApi;