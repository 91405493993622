import habitApi from '../../root/habitApi';

export const REQUEST_USER_BEGIN = 'REQUEST_USER_BEGIN';
export const REQUEST_USER_SUCCESS = 'REQUEST_USER_SUCCESS';
export const REQUEST_USER_FAILURE = 'REQUEST_USER_FAILURE';

export const REQUEST_USERS_BEGIN = 'REQUEST_USERS_BEGIN';
export const REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS';
export const REQUEST_USERS_FAILURE = 'REQUEST_USERS_FAILURE';

export const NEW_USER_BEGIN = 'NEW_USER_BEGIN';
export const NEW_USER_SUCCESS = 'NEW_USER_SUCCESS';
export const NEW_USER_FAILURE = 'NEW_USER_FAILURE';

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function requestUser() {
  return dispatch => {
    dispatch(requestUserBegin());
    return habitApi.endpoints.user.getOne({ id: "" })
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(requestUserSuccess(json));
        return json;
      })
      .catch(error => dispatch(requestUserFailure(error)));
  };

  function requestUserBegin() { return { type: REQUEST_USER_BEGIN }; }
  function requestUserSuccess(user) { return { type: REQUEST_USER_SUCCESS, user }; }
  function requestUserFailure(error) { return { type: REQUEST_USER_FAILURE, error }; }

}

export function requestUsers() {
  return dispatch => {
    dispatch(requestUsersBegin());
    return habitApi.endpoints.users.getAll()
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(requestUsersSuccess(json));
        return json;
      })
      .catch(error => dispatch(requestUsersFailure(error)));
  };

  function requestUsersBegin() { return { type: REQUEST_USERS_BEGIN }; }
  function requestUsersSuccess(users) { return { type: REQUEST_USERS_SUCCESS, users }; }
  function requestUsersFailure(error) { return { type: REQUEST_USERS_FAILURE, error }; }

}

export function deleteUser(user) {
  return dispatch => {
    dispatch(deleteUserBegin());
    return habitApi.endpoints.users.delete(user)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(deleteUserSuccess(user));
        return json;
      })
      .catch(error => dispatch(deleteUserFailure(error)));
  };
  function deleteUserBegin() { return { type: DELETE_USER_BEGIN }; }
  function deleteUserSuccess(user) { return { type: DELETE_USER_SUCCESS, user }; }
  function deleteUserFailure(error) { return { type: DELETE_USER_FAILURE, error: error }; }
}

export function updateUser(user) {
  return dispatch => {
    dispatch(updateUserBegin());
    return habitApi.endpoints.users.update(user)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(updateUserSuccess(json));
        return json;
      })
      .catch(error => dispatch(updateUserFailure(error)));
  };

  function updateUserBegin() { return { type: UPDATE_USER_BEGIN }; }
  function updateUserSuccess(user) { return { type: UPDATE_USER_SUCCESS, user }; }
  function updateUserFailure(error) { return { type: UPDATE_USER_FAILURE, error: error }; }

}

export function newUser(user) {
  return dispatch => {
    dispatch(newUserBegin());
    return habitApi.endpoints.users.create(user)
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch(newUserSuccess(json));
        return json;
      })
      .catch(error => dispatch(newUserFailure(error)));
  };

  function newUserBegin() { return { type: NEW_USER_BEGIN }; }
  function newUserSuccess(json) { return { type: NEW_USER_SUCCESS, newUser: json }; }
  function newUserFailure(error) { return { type: NEW_USER_FAILURE, error: error }; }
}
