import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../../root/Header';
import Footer from '../../root/Footer';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';

import { login, updateUsername, updatePassword } from './actions';
import { requestUser } from '../users/actions';


class LoginController extends Component {

  constructor(props) {
    super(props);
    this.copyright = this.copyright.bind(this);
    this.submit = this.submit.bind(this);
    this.updateUn = this.updateUn.bind(this);
    this.updatePw = this.updatePw.bind(this);
    this.redirectTo = this.redirectTo.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(requestUser());
  }

  copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://material-ui.com/">
          Your Website
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

  updateUn(e) {
    const { dispatch } = this.props;
    dispatch(updateUsername(e.currentTarget.value));
  }

  updatePw(e) {
    const { dispatch } = this.props;
    dispatch(updatePassword(e.currentTarget.value));
  }

  redirectTo() {
  }

  submit() {
    const { dispatch, username, password } = this.props;
    dispatch(login(username, password));
  }

  render() {
    const { currentUser, loggedIn, logout, redirectTo, userIsActive } = this.props;
    if (logout) {
      window.localStorage.setItem('token', null);
      return (<Redirect to="/login" />);
    }

    if (loggedIn && currentUser && userIsActive && !logout) {
      if (redirectTo) {
        return (<Redirect to={redirectTo} />);
      }
      else {
        return (<Redirect to="/thread" />)
      }
    }

    const classes = makeStyles((theme) => ({
      paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    }));

    return (
      <Container component="main" maxWidth="xs">
        <Header />
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="username"
              name="username"
              autoComplete="email"
              onChange={this.updateUn}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={this.updatePw}
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="button"
              onClick={this.submit}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <this.copyright />
        </Box>
        <Footer />
      </Container>
    );
  }
}

LoginController.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  currentUser: PropTypes.object,
  userIsActive: PropTypes.bool,
  loggedIn: PropTypes.bool,
  logout: PropTypes.bool,
  redirectTo: PropTypes.string
};

function mapStateToProps(state) {
  const username = state.login.username || '';
  const password = state.login.password || '';
  const loggedIn = (window.localStorage.getItem('token') !== null);
  const currentUser = state.users.me || null;
  const userIsActive = state.users.activeUser || false;
  return { currentUser, username, userIsActive, password, loggedIn };
}

export default connect(mapStateToProps)(LoginController);
