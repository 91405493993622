import React, { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './Home';
import LoginController from '../routes/login/LoginController';
import HabitContainer from './HabitContainer';
import configureStore from './configureStore';

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { requestContext } from '../routes/context/actions';

/**
 *  /index.js ->
 *    /root/Habit.js ->
 *      LoginController
 *      Home
 *      HabitContainer
 */


const theme = createTheme();

const store = configureStore();

const HabitApp = () => {
  const dispatch = useDispatch();
  const pages = useSelector((state) => {
    const currPages = state.context.sortableData;
    return currPages;
  });

  theme.spacing(1);

  useEffect(() => {
    dispatch(requestContext);
  }, [dispatch]);

  return (
  <Router>
    <Switch>
      <Route exact path='/login' component={LoginController} />
      <PrivateRoute exact path='/organize'>
        <HabitContainer />
      </PrivateRoute>
      <PrivateRoute exact path='/thread'>
        <HabitContainer />
      </PrivateRoute>
      <PrivateRoute exact path='/users'>
        <HabitContainer />
      </PrivateRoute>
      <PrivateRoute exact path='/tags'>
        <HabitContainer />
      </PrivateRoute>
      <PrivateRoute exact path='/context'>
        <HabitContainer />
      </PrivateRoute>
      <PrivateRoute exact path='/groups'>
        <HabitContainer />
      </PrivateRoute>
      <Route path='/' component={Home} />
    </Switch>
  </Router>)
};

function PrivateRoute ({ children, ...rest }) {
  const loggedIn = (window.localStorage.getItem('token') !== null && window.localStorage.getItem('token') !== 'null');
  const userIsActive = useSelector((state) => state.users.activeUser);

  const isAuthenticated = loggedIn && userIsActive;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated
          ? (children)
          : (<Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
             />)}
    />
  );
}

function Habit () {
  theme.spacing(1);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <HabitApp />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default Habit;
