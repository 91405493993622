import {
  NEW_GROUP_BEGIN,
  NEW_GROUP_SUCCESS,
  NEW_GROUP_FAILURE,
  DELETE_GROUP_BEGIN,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILURE,
  UPDATE_GROUP_BEGIN,
  UPDATE_GROUP_SUCCESS,
  UPDATE_GROUP_FAILURE,
  REQUEST_GROUPS_BEGIN,
  REQUEST_GROUPS_SUCCESS,
  REQUEST_GROUPS_FAILURE,
  REQUEST_GROUP_BEGIN,
  REQUEST_GROUP_SUCCESS,
  REQUEST_GROUP_FAILURE,
} from './actions';

function groups(
  state = {
    isDeleting: false,
    isFetching: false,
    isUpdating: false,
    isCreatingNew: false,
    groups: [],
    me: null,
    byId: {},
    allIds: [],
    activeGroup: true
  },
  action
) {
  switch (action.type) {
    case UPDATE_GROUP_BEGIN:
      return Object.assign({}, state, {
        isUpdating: true
      });
    case UPDATE_GROUP_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        groups: state.groups.map((obj) => {
          if (action.group.id !== obj.id) {
            return obj;
          }
          return { ...action.group, text: action.group.text };
        }),
        byId: Object.keys(state.byId).reduce(function (map, obj) {
          if (obj.id === action.group.id) {
            map[action.group.id] = action.group;
          } else {
            map[obj.id] = obj;
          }
          return map;
        }, {})
      });
    case UPDATE_GROUP_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false
      });
    case NEW_GROUP_BEGIN:
      return Object.assign({}, state, {
        isCreatingNew: true
      });
    case NEW_GROUP_SUCCESS:
      return Object.assign({}, state, {
        isCreatingNew: false,
        groups: [action.newGroup, ...state.groups],
        byId: [action.newGroup, ...state.groups].reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        allIds: [action.newGroup, ...state.groups].map(obj => obj.id),
        newGroup: { text: '' }
      });
    case NEW_GROUP_FAILURE:
      return Object.assign({}, state, {
        isCreatingNew: false,
        groups: []
      });
    case DELETE_GROUP_BEGIN:
      return Object.assign({}, state, {
        isDeleting: true
      });
    case DELETE_GROUP_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        groups: state.groups.filter(obj => obj.id !== action.group.id),
        byId: state.allIds.filter(obj => obj !== action.group.id)
          .reduce(function (map, obj) {
            map[obj] = state.byId[obj];
            return map;
          }, {}),
        allIds: state.allIds.filter(obj => obj !== action.group.id),
        newGroup: { text: '' }
      });
    case DELETE_GROUP_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        groups: []
      });
    case REQUEST_GROUPS_BEGIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REQUEST_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        groups: action.groups,
        byId: action.groups.reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        allIds: action.groups.map(obj => obj.id)
      });
    case REQUEST_GROUPS_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        groups: []
      });
    case REQUEST_GROUP_BEGIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REQUEST_GROUP_SUCCESS:
      const activeGroup = (action.group) ? true : false;
      return Object.assign({}, state, {
        isFetching: false,
        me: action.group,
        activeGroup
      });
    case REQUEST_GROUP_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        me: {},
        activeGroup: false
      });
    default:
      return state;
  }
}

export default groups;
