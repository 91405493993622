import { combineReducers } from 'redux';
import items from '../routes/item/reducers';
import threads from '../routes/thread/reducers';
import login from '../routes/login/reducers';
import users from '../routes/users/reducers';
import tags from '../routes/tags/reducers';
import context from '../routes/context/reducers';
import groups from '../routes/groups/reducers';

const rootReducer = combineReducers({
  items, threads, login, users, tags, context, groups
});

export default rootReducer;
