import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';

const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

const useStyles = makeStyles(theme => ({
  card: {
    height: 140,
    width: 200,
    minWidth: 500
  },
  button: {
    margin: theme.spacing(1)
  }
}));

class Item extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newOpen: !props.new,
      inputValue: (this.props.item && this.props.item.text) ? this.props.item.text : '',
      id: (this.props.item && this.props.item.id) ? this.props.item.id : null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleExpandNew = this.toggleExpandNew.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.triggerDelete = this.triggerDelete.bind(this);
  }

  componentDidMount() {
    this.timer = null;
    if (this.props.item && this.props.item.text != null) {
      this.setState({ inputValue: this.props.item.text });
    }
    if (this.props.item && this.props.item.id != null) {
      this.setState({ id: this.props.item.id });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.setState({ inputValue: this.props.text });
    }
  }

  handleChange(e) {
    clearTimeout(this.timer);

    this.setState({ inputValue: e.target.value });

    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.triggerChange();
    }
  }

  toggleExpandNew() {
    let { newOpen } = this.state;
    newOpen = !newOpen;
    this.setState({ newOpen });
  }

  triggerDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.props.item.id);
    }
  }

  triggerChange() {
    const { id, inputValue } = this.state;

    if (this.props.hasOwnProperty('onChange')) {
      if (id && id !== '-1') {
        this.props.onChange(id, inputValue, this.props.threadId);
        console.log('trigger item change ' + inputValue);
      } else {
        this.props.onChange(inputValue, this.props.threadId);
        console.log('trigger item new ' + inputValue);
      }
    }

    if (this.props.new) {
      this.setState({ inputValue: '' });
    }
  }

  render() {
    const { newOpen } = this.state;
    return (
      <Grid item xs={12} ml={1}>
        <Typography component={'span'} color='textSecondary' gutterBottom xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          {this.props.new &&
            <IconButton
              sx={{ display: 'flex' }}
              variant='contained'
              color='secondary'
              className={useStyles.button}
              onClick={this.toggleExpandNew}
              size="small">
              <AddIcon />
            </IconButton>
          }
          <Collapse in={newOpen} sx={{ display: 'flex' }}>
            <TextField
              value={this.state.inputValue}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
              label={this.props.new ? 'new item' : ''}
              variant='standard' />
            {!this.props.new &&
              <span>
                <IconButton
                  variant='contained'
                  color='secondary'
                  className={useStyles.button}
                  onClick={this.triggerDelete}
                  size="large">
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  variant='contained'
                  color='primary'
                  className={useStyles.button}
                  size="large">
                  <CheckIcon />
                </IconButton>
              </span>
            }
          </Collapse>
        </Typography>
      </Grid >
    );
  }
}

Item.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  text: PropTypes.string,
  item: PropTypes.object,
  new: PropTypes.bool,
  threadId: PropTypes.string
};

export default Item;
