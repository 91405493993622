import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';

import { updateThread } from '../actions';


const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

const useStyles = makeStyles(theme => ({
  card: {
    height: 140,
    width: 200,
    minWidth: 500
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const Thread = function (props) {
  const dispatch = useDispatch();
  const [timer, setTimer] = useState(null);

  const defOpen = (props.hasOwnProperty('open')) ? props.open : false;
  const [open, setOpen] = useState(defOpen);

  const defNewOpen = !defOpen;
  const [newOpen, setNewOpen] = useState(defNewOpen);

  const defaultInput = (props.thread && props.thread.text) ? props.thread.text : '';
  const [inputValue, setInputValue] = useState(defaultInput);

  const id = (props.thread && props.thread.id) ? props.thread.id : null;

  const triggerChange = useCallback(() => {

    if (props.hasOwnProperty('onChange')) {
      if (props.new) {
        (id) ? props.onChange(id, inputValue) : props.onChange(inputValue);
      }
      else {
        if (id) {
          dispatch(updateThread({ id: id, text: inputValue }));
        }
      }
    }

    if (props.new) {
      setInputValue('');
    }

    console.log('trigger ' + inputValue);
  }, [id, inputValue, props, dispatch]);

  useEffect(() => {
    clearTimeout(timer);
    if (inputValue !== defaultInput) {
      setTimer(setTimeout(triggerChange, WAIT_INTERVAL));
    }

  }, [inputValue, defaultInput, triggerChange, timer]);

  function toggleExpand() {
    setOpen(!open);
    if (props.toggleContainer) {
      props.toggleContainer(!open);
    }
  }

  function toggleExpandNew() {
    setNewOpen(!newOpen);
  }

  function handleChange(e) {
    setInputValue(e.target.value);
  }

  function handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(timer);
      triggerChange();
    }
  }

  function triggerDelete() {
    props.onDelete(id);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Typography component={'span'} color='textSecondary' sx={{ display: 'flex', alignItems: 'center' }}>
        {props.new &&
          <IconButton
            sx={{ display: 'flex' }}
            variant='contained'
            color='secondary'
            className={useStyles.button}
            onClick={toggleExpandNew}
            size="small">
            <AddIcon />
          </IconButton>
        }
        <Collapse in={newOpen} sx={{ display: 'flex' }}>
          {!props.new &&
            <IconButton
              variant='contained'
              color='secondary'
              className={useStyles.button}
              onClick={toggleExpand}
              size="small">
              {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
          }
          <TextField
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            label={props.new ? 'new thread' : ''}
            size="small"
            variant='standard' />
          {!props.new &&
            <IconButton
              variant='contained'
              color='secondary'
              className={useStyles.button}
              onClick={triggerDelete}
              size="small">
              <DeleteIcon />
            </IconButton>
          }
        </Collapse>
      </Typography>
    </Box>
  );
}

Thread.propTypes = {
  toggleContainer: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onKeyDown: PropTypes.func,
  text: PropTypes.string,
  thread: PropTypes.object,
  newItem: PropTypes.object,
  new: PropTypes.bool,
  open: PropTypes.bool
};

export default Thread;
