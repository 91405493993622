import {
  UPDATE_THREAD_BEGIN,
  UPDATE_THREAD_SUCCESS,
  UPDATE_THREAD_FAILURE,
  NEW_THREAD_BEGIN,
  NEW_THREAD_SUCCESS,
  NEW_THREAD_FAILURE,
  DELETE_THREAD_BEGIN,
  DELETE_THREAD_SUCCESS,
  DELETE_THREAD_FAILURE,
  REQUEST_THREADS_BEGIN,
  REQUEST_THREADS_SUCCESS,
  REQUEST_THREADS_FAILURE,
  REQUEST_ROOT_BEGIN,
  REQUEST_ROOT_SUCCESS,
  REQUEST_ROOT_FAILURE
} from './actions';

function threads(
  state = {
    isDeleting: false,
    isFetching: false,
    isUpdating: false,
    isCreatingNew: false,
    threads: [],
    byId: {},
    allIds: [],
    activeThread: null,
    newThread: { text: '' },
    rootId: 'root'
  },
  action
) {
  switch (action.type) {
    case UPDATE_THREAD_BEGIN:
      return Object.assign({}, state, {
        isUpdating: true
      });
    case UPDATE_THREAD_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        threads: state.threads.map((obj) => {
          if (action.item.id !== obj.id) {
            return obj;
          }
          return action.item;
        }),
        byId: Object.keys(state.byId).reduce(function (map, obj) {
          if (obj.id === action.item.id) {
            map[action.item.id] = action.item;
          } else {
            map[obj.id] = obj;
          }
          return map;
        }, state.byId),
        activeThread: (action.item.id === 'root') ? action.item : state.activeThread
      });
    case UPDATE_THREAD_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false
      });
    case NEW_THREAD_BEGIN:
      return Object.assign({}, state, {
        isCreatingNew: true
      });
    case NEW_THREAD_SUCCESS:
      return Object.assign({}, state, {
        isCreatingNew: false,
        threads: [action.newThread, ...state.threads],
        byId: [action.newThread, ...state.threads].reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        allIds: [action.newThread, ...state.threads].map(obj => obj.id),
        newThread: { text: '' }
      });
    case NEW_THREAD_FAILURE:
      return Object.assign({}, state, {
        isCreatingNew: false,
        threads: []
      });
    case DELETE_THREAD_BEGIN:
      return Object.assign({}, state, {
        isDeleting: true
      });
    case DELETE_THREAD_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        threads: state.threads.filter(obj => obj.id !== action.thread.id),
        byId: state.allIds.filter(obj => obj !== action.thread.id)
          .reduce(function (map, obj) {
            map[obj] = state.byId[obj];
            return map;
          }, {}),
        allIds: state.allIds.filter(obj => obj !== action.thread.id),
        newThread: { text: '' }
      });
    case DELETE_THREAD_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        threads: []
      });
    case REQUEST_THREADS_BEGIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REQUEST_THREADS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        threads: action.threads,
        byId: action.threads.reduce(function (map, obj) {
          map[obj.id] = obj;
          return map;
        }, {}),
        allIds: action.threads.map(obj => obj.id)
      });
    case REQUEST_THREADS_FAILURE:
      return Object.assign({}, state, {
        isDeleting: false,
        threads: []
      });
    case REQUEST_ROOT_BEGIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case REQUEST_ROOT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        activeThread: action.thread
      });
    case REQUEST_ROOT_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        activeThread: null
      });
    default:
      return state;
  }
}

export default threads;
