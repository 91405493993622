
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}));

function Footer() {
  const classes = useStyles();
  return (
    <Container maxWidth='lg'>
      <Grid container className={classes.root} spacing={2} direction='column' aligncontent='flex-start'>
        <h6>Copyright T. Wa`-jiw Casey 2023</h6>
      </Grid>
    </Container>
  );
}

export default Footer;
