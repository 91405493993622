import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import Project from './Project';
import { requestThreads } from '../../thread/actions.js';
import { requestUser } from '../../users/actions.js';
import { requestItems } from '../../item/actions.js';
import { requestContext } from '../../context/actions.js';
import { requestTags } from '../../tags/actions.js';

const OrganizeContainer = function () {
  const dispatch = useDispatch();
  // const items = useSelector((state) => state.items.items);
  const threads = useSelector((state) => state.threads.threads);
  const thisContext = useSelector((state) => state.context.byName['OrganizeContainer']);

  const cxChildren = useSelector((state) => {
    const cxById = state.context.byId;
    const thisCx = state.context.byName['OrganizeContainer'];

    if(cxById && thisCx) {
      return thisCx.children.reduce(
        (arr, child) => {
          const childObject = cxById[child];
          if(childObject) {
            arr.push(childObject);
          }
          return arr;
        }, []);
    }
    
    return [];
  })

  // const [cxChildren, setCxChildren] = useState([]);

  // How to get Context Children... 
  // 1. get cx ids from this context.children
  // 2. for each filter by root type
  // 3. loop components for each in container
  // * will need children relationships to customize 

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  useEffect(() => {
    dispatch(requestThreads());
    dispatch(requestUser());
    dispatch(requestItems());
    dispatch(requestContext());
    dispatch(requestTags());
  }, [dispatch]);

  return (
    <Container>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
      <Typography
        component="span"
        variant="h2"
        align="left"
        color="text.primary"
        gutterBottom
      >
        Organize
      </Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Typography
            component="span"
            variant="h6"
            align="left"
            color="text.primary"
            gutterBottom
          >
            {thisContext && thisContext.id ? thisContext.id : ''}
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Typography
            component="span"
            variant="h6"
            align="left"
            color="text.primary"
            gutterBottom
          >
            {cxChildren && cxChildren.length ? `Children: ${cxChildren.length}` : ''}
        </Typography>
      </Box>
      <IconButton
        sx={{ display: 'flex' }}
        variant='contained'
        color='secondary'
        size="small">
        <AddIcon />
      </IconButton>
      <Grid
        container
        spacing={2}>
        {threads && threads.map((project) =>
          (<Project id={project.id}/>)
        )}
      </Grid>
    </Container>

  );
}

OrganizeContainer.propTypes = {
};

export default OrganizeContainer;
