import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

import { deleteThread, updateThread } from '../actions';

import ThreadContainer from './ThreadContainer';

const SubThreadContainer = function (props) {
  const dispatch = useDispatch();

  const threadsById = useSelector((state) => state.threads.byId ? state.threads.byId : []);
  const currThread = (threadsById.hasOwnProperty(props.threadId)) ? threadsById[props.threadId] : undefined;

  const id = (currThread && currThread.id) ? currThread.id : null;

  function onChangeThread(id, inputValue) {
    this.props.onChange(id, inputValue);
  }
  
  function dispatchDeleteThread(threadId) {
    dispatch(deleteThread({ id: threadId })).then(() => {
      const newThreads = currThread.children.threads.reduce((threads, id) => {
        if (id !== threadId) {
          threads.push(id);
        }
        return threads;
      }, []);
      currThread.children.threads = newThreads;
      dispatch(updateThread(currThread));
    });
  }

  function dispatchNewThread(inputText) {
    props.onNewThread(inputText, id);
  }

  return (
    <Box>
      {!props.new && currThread && currThread.children && currThread.children.threads &&
        currThread.children.threads.map((threadId) => {
          console.log("sub thread id: " + threadId);
          return (
            threadId && props.threadsById.hasOwnProperty(threadId) &&
            // <div />
            <ThreadContainer threadId={threadId + ''} key={threadId}
              threadsById={props.threadsById}
              onChange={onChangeThread}
              onDelete={dispatchDeleteThread}
              onNewThread={dispatchNewThread}
              onNewItem={props.onNewItem}
              onChangeItem={props.onChangeItem}
              onDeleteItem={props.onDeleteItem}
            />
          )
        })
      }
    </Box>
  );
}

SubThreadContainer.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onNewItem: PropTypes.func,
  onChangeItem: PropTypes.func,
  onDeleteItem: PropTypes.func,
  text: PropTypes.string,
  newItem: PropTypes.object,
  new: PropTypes.bool,
  threadId: PropTypes.string
};

export default SubThreadContainer;
