import React, { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';

import { SimpleTreeItemWrapper } from "dnd-kit-sortable-tree";

import { newContext, openModal, deleteContext, updateContext } from '../actions';

const ENTER_KEY = 13;
const useStyles = makeStyles(theme => ({
  card: {
    height: 140,
    width: 200,
    minWidth: 500
  },
  button: {
    margin: theme.spacing(1)
  },
  li: {
    listStyle: 'none'
  }
}));

const ContextComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const [item, setItem] = useState(props.item);
  const [isEditable, setIsEditable] = useState(false);
  const contextById = useSelector((state) => state.context.byId);
  const [inputValue, setInputValue] = useState(item.text);

  const addButtonClick = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      dispatch(newContext({ text: inputValue })).then((context) => {
        if (context) {
          const currentContext = contextById[item.id];
          if (!currentContext.children) {
            currentContext.children = [];
          }
          currentContext.children = [...currentContext.children, context.id];
          dispatch(updateContext(currentContext));
        }
      }
      );
    }
  };

  const editButtonClick = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      console.log(item.id);
      // setIsEditable(!isEditable);
      dispatch(openModal(item.id));
    }
  };

  const nameClick = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      console.log(item.id);
      setIsEditable(!isEditable);
    }
  };

  const deleteButtonClick = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      console.log(item.id);
      // setIsEditable(!isEditable);
      if( window.confirm("Are you sure you want to delete this context?") ) {
        dispatch(deleteContext(item));
      }
    }
  };

  const onBlur = () => {
    setIsEditable(false);
  }

  function handleChange(e) {
    const newValue = e.target.value;
    const currentContext = contextById[item.id];
    currentContext.text = newValue;
    setItem({ ...item, text: newValue });
    setInputValue(newValue);
  }

  function stopPropagation(e) {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
  }

  const onKeyDown = (e) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
      if (e.keyCode === ENTER_KEY) {
        const currentContext = contextById[item.id];
        dispatch(updateContext(currentContext));
        setIsEditable(!isEditable);
      }
    }
  }

  return (
    /* you could also use FolderTreeItemWrapper if you want to show vertical lines.  */
    <SimpleTreeItemWrapper {...props} ref={ref} key={props.item.id} >
      <>
        {!isEditable ? 
        <span onClick={nameClick}>{inputValue}</span> 
        :
          <TextField
            value={inputValue}
            label={props.new ? 'Context Name' : ''}
            size="small"
            onBlur={onBlur}
            onClick={stopPropagation}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            variant='standard' />
        }
        <span className='button-action-bar'>
          <IconButton
            sx={{ display: 'flex' }}
            variant='contained'
            color='secondary'
            className={useStyles.button}
            onClick={editButtonClick}
            size="small">
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{ display: 'flex' }}
            variant='contained'
            color='secondary'
            className={useStyles.button}
            onClick={addButtonClick}
            size="small">
            <AddIcon />
          </IconButton>
          <IconButton
            sx={{ display: 'flex' }}
            variant='contained'
            color='secondary'
            className={useStyles.button}
            onClick={deleteButtonClick}
            size="small">
            <DeleteIcon />
          </IconButton>
        </span>
      </>
    </SimpleTreeItemWrapper>
  )
});

export default ContextComponent;