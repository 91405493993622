import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import OrganizeContainer from '../routes/organize/components/OrganizeContainer';
import AllThreadsContainer from '../routes/thread/components/AllThreadsContainer';
import UserContainer from '../routes/users/components/UserContainer';
import TagContainer from '../routes/tags/components/TagContainer';
import ContextContainer from '../routes/context/components/ContextContainer';
import GroupContainer from '../routes/groups/components/GroupContainer';
import AccountIcon from './AccountIcon';
import Footer from './Footer';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import AppBar from './components/AppBar';
import DrawerHeader from '../components/DrawerHeader';
import Main from './components/Main';

const drawerWidth = 200;
const HabitContainer = function () {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position='fixed' open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-describedby=''
            aria-label='open drawer'
            onClick={() => {
              setOpen(true);
            }}
            edge='start'
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
            habit
          </Typography>
          <AccountIcon />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant='persistent'
        anchor='left'
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={() => {
              setOpen(false);
            }}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem button key='organize'>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <Link to='/organize' underline='none'>
              <ListItemText primary='Organize' />
            </Link>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key='threads'>
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <Link to='/thread' underline='none'>
              <ListItemText primary='Threads' />
            </Link>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key='users'>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <Link to='/users' underline='none'>
              <ListItemText primary='users' />
            </Link>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key='tags'>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <Link to='/tags' underline='none'>
              <ListItemText primary='tags' />
            </Link>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key='context'>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <Link to='/context' underline='none'>
              <ListItemText primary='context' />
            </Link>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key='groups'>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <Link to='/groups' underline='none'>
              <ListItemText primary='groups' />
            </Link>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Router>
          <Switch>
            <Route path='/organize'>
              <OrganizeContainer />
            </Route>
            <Route path='/thread'>
              <AllThreadsContainer />
            </Route>
            <Route path='/users' component={UserContainer} />
            <Route path='/tags' component={TagContainer} />
            <Route path='/context' component={ContextContainer} />
            <Route path='/groups' component={GroupContainer} />
          </Switch>
        </Router>
        <Footer />
      </Main>
    </Box >
  );
}

export default HabitContainer;
