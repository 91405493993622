import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import { deleteUser, newUser, requestUsers, updateUser } from '../actions';

const useStyles = makeStyles(theme => ({
  card: {
    height: 140,
    width: 200,
    minWidth: 500
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const columns = [
  { field: 'id', headerName: 'ID', width: 150 },
  { field: 'username', headerName: 'username', width: 150, editable: true },
  { field: 'password', headerName: 'password', width: 150, editable: true },
  { field: 'email', headerName: 'email', width: 300, editable: true },
  { headerName: 'delete', width: 50 },
];

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const UserContainer = function (props) {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const currentUsers = useSelector((state) => state.users.users);
  const newUserRow = {
    id: 'new'
  };
  const users = [newUserRow, ...currentUsers] || [];
  const [lastEditRowsModel, setLastEditRowsModel] = React.useState({});
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const [lastSelectedRow, setLastSelectedRow] = React.useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);

  useEffect(() => {
    dispatch(requestUsers());
  }, [dispatch]);

  const handleRowClick = useCallback((params) => {
    setLastSelectedRow(params.id)
  }, []);

  const handleSelectionModelChange = useCallback((params) => {
    setSelectedRows(params)
  }, []);

  const triggerDelete = function () {
    dispatch(deleteUser({ id: selectedRows[0] }));
  };

  const handleEditRowsModelChange = useCallback((model) => {
    if (Object.entries(model).length === 0) {
      //place to commit last edit
      const hasNew = lastEditRowsModel.hasOwnProperty('new');
      const hasUN = hasNew && lastEditRowsModel['new'].hasOwnProperty('username') && lastEditRowsModel['new'].username.value !== '';
      const hasPW = hasNew && lastEditRowsModel['new'].hasOwnProperty('password') && lastEditRowsModel['new'].password.value !== '';
      const hasValidEmail = hasNew && lastEditRowsModel['new'].hasOwnProperty('email') && validateEmail(lastEditRowsModel['new'].email.value);
      const validParams = hasUN && hasPW && hasValidEmail;
      if (hasNew && validParams) {
        const user = {
          username: lastEditRowsModel['new'].username.value,
          password: lastEditRowsModel['new'].password.value,
          email: lastEditRowsModel['new'].email.value,
        };
        dispatch(newUser(user));
        setEditRowsModel({ ...model });
      }
      else {
        const id = Object.keys(lastEditRowsModel)[0];
        const user = {
          id: id,
          username: lastEditRowsModel[id].username.value,
          password: lastEditRowsModel[id].password.value,
          email: lastEditRowsModel[id].email.value,
        };
        dispatch(updateUser(user));
        setEditRowsModel({ ...model });
      }
    }
    else {
      setEditRowsModel({ ...model });
      setLastEditRowsModel({ ...model });
    }
  }, [dispatch, lastEditRowsModel]);

  return (
    <Paper>
      <Alert severity="info" style={{ marginTop: 8 }}>
        <code>editRowsModel: {JSON.stringify(editRowsModel)}</code>
        <br />
        <code> lastEditRowsModel: {JSON.stringify(lastEditRowsModel)}</code>
        <br />
        <code> last selected row: {JSON.stringify(lastSelectedRow)}</code>
        <br />
      </Alert>
      {selectedRows.length > 0 &&
        <IconButton
          variant='contained'
          color='secondary'
          className={useStyles.button}
          onClick={triggerDelete}
          size="small">
          <DeleteIcon />
        </IconButton>
      }
      <Paper sx={{ height: 300, width: '100%' }}>
        <DataGrid
          editMode="row"
          rows={users}
          columns={columns}
          editRowsModel={editRowsModel}
          onEditRowsModelChange={handleEditRowsModelChange}
          onRowClick={handleRowClick}
          onSelectionModelChange={handleSelectionModelChange}
          apiRef={apiRef}
        />
      </Paper>
    </Paper>
  );
};

export default UserContainer;
