import React from 'react';

import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const accountButtonRef = React.createRef();

  const handleMenu = () => {
    setAnchorEl(accountButtonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        ref={accountButtonRef}
        sx={{ display: 'flex' }}
        size="large"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="simple-popover"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleClose}>
          <Link href="/logout" underline="none">Logout</Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AccountMenu;